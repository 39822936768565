<template>
  <div class="contents-section">
    <div class="title-section">
      <h2 class="d-flex align-start">
        권한프리셋관리
        <v-tooltip right color="black">
          <template #activator="{ on, attrs }">
            <v-icon
              class="question"
              small
              color="warning"
              v-on="on"
              v-bind="attrs"
            >mdi-help-circle-outline</v-icon>
          </template>
          <span>
            자주 사용하는 권한을 프리셋으로 저장하여 사용합니다.
            <br>
            계정에 권한을 부여할때 프리셋을 불러와서 빠르게 적용할 수 있습니다.
            <br>
            ※ 프리셋을 수정해도 계정의 권한은 변경되지 않습니다.
          </span>
        </v-tooltip>
        <v-tooltip right color="black">
          <template #activator="{ on, attrs }">
            <v-btn
              icon class="mt-1 ml-1"
              v-bind="attrs" v-on="on"
              :loading="!presetLoad"
              color="primary"
              @click="getPresetList"
            >
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </template>
          <span>새로고침</span>
        </v-tooltip>
      </h2>
      <v-btn
        v-if="presetLoad === true"
        depressed
        color="primary"
        @click="insertModalOpen()"
      >
        권한프리셋등록
      </v-btn>
    </div>
    <v-progress-linear
      v-if="presetLoad === false"
      indeterminate
      color="primary"
    ></v-progress-linear>
    <v-simple-table class="mt-10" v-else-if="presetLoad === true && presetList.length > 0">
      <template #default>
        <tbody>
          <tr
            v-for="item in presetList"
            :key="item.no"
          >
            <td>{{ item.name }}</td>
            <td class="text-right">
              <v-btn
                depressed
                @click="updateModalOpen(item.permission, item.name, item.no)"
              >
                수정
              </v-btn>
              <v-btn
                depressed
                color="error"
                class="ml-3"
                @click="deleteConfirmOpen(item.no, item.name)"
              >삭제</v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <div class="empty-msg"
      v-else-if="presetLoad === true && presetList.length === 0"
    >등록된 프리셋이 없습니다.</div>
    <dlg-user-auth
      :show.sync="dialog.show"
      :type="dialog.type"
      :uk="dialog.uk"
      :name.sync="dialog.name"
      :permission.sync="dialog.permission"
      :func="getPresetList"
      :presets="presetList"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import DlgUserAuth from '@/components/dialog/DlgUserAuth.vue';
import comm from '@/util/comm';

export default {
  components: { DlgUserAuth },
  data: () => ({
    comm,
    dialog: {
      show: false,
      type: 1,
      uk: null,
      name: null,
      permission: {},
    },
    defaultPreset: null,
    presetList: [],
    presetLoad: false,
  }),
  computed: {
    ...mapGetters({
      userInfo: 'auth/userInfo',
    }),
  },
  methods: {
    ...mapMutations({
      progress: 'dialog/progress',
      confirm: 'dialog/confirm',
    }),
    ...mapActions({
      alert: 'dialog/alert',
    }),
    updateModalOpen(auth, name, no) {
      const authObj = JSON.parse(JSON.stringify(auth));
      this.dialog.permission = comm.makeUserPermissionUpToDate(
        this.defaultPreset,
        authObj,
      );
      this.dialog.name = name;
      this.dialog.uk = String(no);
      this.dialog.type = 3;
      this.dialog.show = true;
    },
    insertModalOpen() {
      if (this.defaultPreset === null) {
        this.alert(['error', '오류가 발생했습니다. 다시 시도해주세요.']);
      } else {
        const authObj = JSON.parse(JSON.stringify(this.defaultPreset));

        this.dialog.permission = authObj;
        this.dialog.name = null;
        this.dialog.uk = null;
        this.dialog.type = 2;
        this.dialog.show = true;
      }
    },
    deleteConfirmOpen(no, name) {
      const refresh = () => {
        this.getPresetList();
      };
      const func = () => {
        this.$socket.emit('permissions.preset.delete', { no },
          (resp) => {
            if (resp.result === 'success') {
              this.confirm({ show: false });
              this.alert(['info', '삭제되었습니다.']);
              refresh();
            } else {
              this.confirm({ show: false });
              this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
              console.error(resp);
            }
          });
      };
      this.confirm({
        show: true,
        func,
        msg: `${name} 프리셋을 정말 삭제할까요?`,
        btnText: '삭제',
        color: 'error',
      });
    },
    getPresetList() {
      this.presetLoad = false;
      this.$socket.emit('permissions.preset.list.get', {
        page: 1,
        itemsPerPage: 0,
        sortBy: [],
        sortDesc: [],
        filters: [],
      }, (resp) => {
        if (resp.result === 'success') {
          const presets = resp.items.filter((r) => r.no !== 1);
          const defaultPreset = resp.items.filter((r) => r.no === 1)[0].permission;
          this.presetList = presets;
          this.defaultPreset = defaultPreset;
          this.presetLoad = true;
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`], false);
          console.error(resp);
        }
      });
    },
  },
  watch: {
    presetList: {
      immediate: true,
      handler() {
      },
    },
    userInfo: {
      immediate: true,
      handler(val) {
        if (val !== null) {
          this.getPresetList();
        }
      },
    },
  },
  mounted() {
  },
};
</script>

<style scoped lang="scss">
.empty-msg {
  padding: 20px 20px;
  background-color: #f2f2f2;
  border-radius: 4px;
}
.question {
  cursor: pointer;
}
</style>
